import React from 'react';
import { RouteFormatter } from '../routes';

const MyLink = ({ href = '', text = '' }) => (
    <a href={href} target="_blank" rel="noreferrer" className="snif-semibold text-primary text-underline">
        {text}
    </a>
);

export const HOME_FAQS_CONTENT = [
    {
        que: 'What inspired Sniffspot to offer a network of private dog parks?',
        ans: () => (
            <>
                <p className="mb-1">
                    Sniffspot's founder, David Adams, lives with a leash reactive dog and a dog selective dog. Back in
                    2016, he and his wife Rebecca were struggling to meet their pup Toshii's basic needs in a city
                    environment as there was nowhere safe to let their guards down. In passing, Rebecca texted{' '}
                    <MyLink
                        href={RouteFormatter.web.about()}
                        text="that she wished “there was an app to find places to let him off leash”"
                    />{' '}
                    — and the rest, as they say, is history.
                </p>
                <p>
                    David is heavily involved in the day-to-day operations of Sniffspot. His personal experiences have
                    made him passionate about giving all dogs the opportunity to sniff, run, and play without fear! As
                    long as we share a place on Earth, Sniffspot believes that dogs have an inherent right to live a
                    fulfilling, joyful, and rewarding life just like we do.
                </p>
            </>
        ),
    },
    {
        que: 'What kind of dogs benefit from private dog parks?',
        ans: () => (
            <>
                <p className="mb-1">The short answer: All dogs!</p>
                <p className="mb-1">
                    Because they're private spaces without the chaos of many public city parks, Sniffspots are
                    especially well suited to dogs who are nervous,{' '}
                    <MyLink href="https://www.sniffspot.com/blog/dog-training" text="in training" />, or{' '}
                    <MyLink href="https://www.sniffspot.com/blog/dog-reactivity" text="working through reactivity" />.
                    But every pet can benefit from time with just their owners in an environment all to themselves.
                </p>
                <p className="mb-1">Here are a few things guests commonly use Sniffspots for:</p>
                <ul>
                    <li>
                        Working on training skills, like recall, in an environment with moderate distractions (a good
                        middle step between your own home and busier areas out in public)
                    </li>
                    <li>
                        Having playdates with one or two trusted dogs, either just for fun or to help build healthy
                        social skills
                    </li>
                    <li>
                        Being able to run around in a securely fenced space especially if owners don't have a yard of
                        their own
                    </li>
                    <li>
                        Safely exposing dogs to new environments, which is a key part of proper socialization — many
                        Sniffspots offer varying terrain like water features plus a range of objects like agility
                        equipment that can help your pup get comfortable in more situations
                    </li>
                    <li>
                        Changing up regular walking and play routines to provide more variety and fulfillment — taking
                        the same route or playing in the same park every day in a row can get boring for our pets!
                    </li>
                </ul>
            </>
        ),
    },
    {
        que: "Aren't public dog parks good for dogs?",
        ans: () => (
            <>
                <p className="mb-1">
                    It's important for most modern dogs to have off leash exercise where they're able to engage in
                    natural behaviors like sniffing and running. Since letting dogs off leash in city parks can be
                    dangerous for the surrounding community and wildlife — not to mention can lead to issues with animal
                    control or park authority departments — public dog parks are a legal way to let dogs have off leash
                    exercise.
                </p>
                <p className="mb-1">
                    Unfortunately, the free and open nature of public dog parks has drawbacks. There might be
                    unsocialized dogs displaying aggressive behavior, sick pets leading to disease transmission, or
                    left-behind waste and other sanitation issues (since any given public dog park facility may not be
                    cleaned regularly). All this aside, other dog owners are often inattentive of their dogs —
                    particularly in popular parks specifically designed for pets to run off leash. Some people think of
                    these areas like babysitters for their dogs and don't stay engaged in their pets' behavior.
                </p>
                <p>
                    For these reasons, many dog behaviorists discourage visiting public dog parks and recommend
                    Sniffspot's private dog parks instead. Sniffspot private dog parks only allow one guest to book at a
                    time. They are not open to the public — a reservation is required ahead of time, with both guests
                    and hosts agreeing to certain protocols to ensure there aren't any unexpected surprises in the space
                    — which solves many of these issues.
                </p>
            </>
        ),
    },
    {
        que: 'How do I know if a private Sniffspot dog park works for my dog?',
        ans: () => (
            <>
                <p className="mb-1">
                    Dogs enjoy Sniffspot dog parks for all kinds of different reasons — spanning from physical exercise
                    to behavior modification training to agility or other dog sports practice and more. Whatever your
                    primary goals, there is a Sniffspot dog park for you!
                </p>
                <p className="mb-1">
                    There are many different types of private dog parks available. Sniffspot hosts offer locations like
                    designated training yards; expansive fields; large, fully-fenced off-leash dog parks; indoor
                    facilities with lights and other amenities; dog sports courses complete with agility equipment;
                    pools and natural water features; and more. Some even have niche amenities, such as a park splash
                    pad and dog-friendly potable water fountains.
                </p>
                <p>
                    You can filter Sniffspot dog parks by size, fencing, and distractions that might be present via our
                    dog park locator. If your dog doesn't have a good recall, you can visit one of our fully fenced dog
                    parks. If your dog is reactive to other dogs, you can visit one of our off-leash areas where there
                    are no dogs audible or visible nearby. You can also filter locations to avoid other domestic animals
                    and people! Each listing includes the park hours of operation — and by reading reviews you can
                    understand the status of condition, for example, if a section of the fence is in poor condition or
                    there are any park maintenance issues.
                </p>
            </>
        ),
    },
    {
        que: 'How does Sniffspot ensure their private dog parks are safe?',
        ans: () => (
            <>
                <p className="mb-1">
                    While public dog parks have a number of safety issues, such as aggressive dogs, disease
                    transmission, and general cleanliness (often as much an issue with the person in charge as with
                    their dog) Sniffspot dog parks are much safer environments to let your dog express their natural
                    behaviors.
                </p>
                <h4 className="snif-p snif-semibold mb-05">
                    All Sniffspot bookings are private with a time buffer in between
                </h4>
                <p className="mb-1">
                    With Sniffspot, all bookings are private — the only park users at a given time are the dog owners
                    who signed up and dogs they bring with them in their own group. Any host dogs are required to be
                    kept away from the spot during visits.
                </p>
                <p className="mb-1">
                    We also enforce buffers between bookings to ensure you aren't arriving or leaving at the same time
                    another park user is present.
                </p>
                <h4 className="snif-p snif-semibold mb-05">Sniffspot requires visiting dogs to have vaccinations</h4>
                <p className="mb-1">We require all dogs to be vaccinated or have equivalent titers.</p>
                <h4 className="snif-p snif-semibold mb-05">
                    Sniffspot listings are vetted and reviewed by guests like you
                </h4>
                <p className="mb-1">
                    Perhaps most helpful to know exactly what you're getting into: Sniffspot dog park locations are
                    vetted and reviewed by guests, so you can find the perfect place for you and your dog — whether they
                    have behavioral struggles, special needs, or any other specific considerations. 93% of reviews on
                    Sniffspot visits are 5 stars and many spots have been reviewed hundreds of times.
                </p>
                <h4 className="snif-p snif-semibold mb-05">Hosts are able to inventory their dog parks firsthand</h4>
                <p>
                    Hosts are in charge of keeping their park areas clean, and many go out of their way to provide
                    visitors with exceptional experiences. Because each host can control the number of guests that visit
                    in a given time period, they're able to maintain their spaces better than many public parks
                    departments can. It's a win-win for everyone in the community!
                </p>
            </>
        ),
    },
    {
        que: 'How do I sign up to use Sniffspot?',
        ans: () => (
            <p>
                The Sniffspot app makes it super easy to book time at private dog parks near you.{' '}
                <MyLink
                    href={RouteFormatter.signUp()}
                    text="You can create a completely free account by clicking this link"
                />
                ! We're constantly updating our iOS and Android app experiences along with our website to provide a
                seamless user experience.
            </p>
        ),
    },
    {
        que: 'How can I find Sniffspot dog parks near me?',
        ans: () => (
            <p>
                You can{' '}
                <MyLink
                    href={RouteFormatter.listings({})}
                    text="browse all of our Sniffspots here using our dog park locator"
                />
                . You can easily view information about the spots, including open times, park entrance details, and
                current conditions.
            </p>
        ),
    },
    {
        que: 'How much do Sniffspot dog parks cost?',
        ans: () => (
            <>
                <p className="mb-1">
                    Each host sets their own price, so you can check the cost per hour on the specific Sniffspot dog
                    park listing. Most are between $5 - $15 per dog per hour.
                </p>
                <p>
                    Prices vary based on size, fencing and amenities, such as water or drinking fountains for dogs, park
                    benches, WiFi accessibility, and so on.
                </p>
            </>
        ),
    },
    {
        que: 'What are the requirements to bring my dog to a Sniffspot dog park?',
        ans: () => (
            <>
                <h4 className="snif-p snif-semibold mb-05">Vaccinations are required</h4>
                <p className="mb-1">
                    We require that all dogs have basic vaccinations or equivalent titers. We require rabies
                    vaccination, the equivalent of current rabies tags.
                </p>
                <h4 className="snif-p snif-semibold mb-05">Dogs should not display aggressive behavior</h4>
                <p className="mb-1">We do not allow dogs with a bite history.</p>
                <h4 className="snif-p snif-semibold mb-05">There are sometimes limits on number of dogs per booking</h4>
                <p className="mb-1">
                    We do not have limits for dogs per owner or dogs per adult handler, though some individual park
                    hosts set person per visit limits.
                </p>
                <h4 className="snif-p snif-semibold mb-05">Supervision is always required</h4>
                <p className="mb-1">Adult supervision is required at all times.</p>
                <h4 className="snif-p snif-semibold mb-05">Clean up after your pets</h4>
                <p className="mb-1">
                    Dog owners are responsible for picking up pet waste to keep park areas clean (many Sniffspot private
                    dog parks provide pet waste stations).
                </p>
                <h4 className="snif-p snif-semibold mb-05">You must sign a waiver and agree to our policies</h4>
                <p>
                    We require that guests sign our waiver and agree to follow park rules and policies before visiting
                    park sites.
                </p>
            </>
        ),
    },
    {
        que: 'How can I offer my land as a private dog park?',
        ans: () => (
            <>
                <p>
                    <MyLink
                        href={RouteFormatter.hostLanding()}
                        text="You can learn more about how you can earn up to $3,000 per month sharing your land here"
                    />
                    . It is just as lucrative as dog boarding or other dog services — with less work for you! Park
                    planning can be as simple as opening up your existing yard.
                </p>
            </>
        ),
    },
];
