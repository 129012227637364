import React from 'react';
import bemCn from 'bem-cn';
import PropTypes from 'prop-types';
import PictureSet from '../picture-set';
import './style.scss';

const b = bemCn('circle-image');

const CircleImage = ({ image, className, useState, SSR, method }) => {
    return <PictureSet imgs={image} className={b.mix(className)} alt="avatar" isAvatar {...{ useState, SSR, method }} />;
};

CircleImage.propTypes = {
    image: PropTypes.string,
};

export default CircleImage;
