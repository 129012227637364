import React, { useEffect, useRef, useState } from 'react';
import bemCn from 'bem-cn';
import moment from 'moment-timezone';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { ImageHelper } from '../../classes/image-helper.class';
import { isEllipsisActive } from '../../helpers/common';
import UserCircleImage from '../user-circle-image';
import SniffButton from '../sniff-button';
import SeoReview from '../seo-review';
import Rating from '../rating/index';
import './style.scss';

const b = bemCn('review-card');
const bseo = bemCn('review-card-seo');

const ReviewCard = ({ review, useEffect: useEffectSSR, useState: useStateSSR, useRef: useRefSSR }) => {
    const useRefC = useRefSSR || useRef;
    const useEffectC = useEffectSSR || useEffect;
    const useStateC = useStateSSR || useState;

    const textRef = useRefC(null);
    const [needExpand, setNeedExpand] = useStateC(false);
    const date = moment(review.createdAt).tz('America/New_York').format('MMMM Y');

    useEffectC(() => {
        if (textRef?.current) {
            setNeedExpand(isEllipsisActive(textRef.current));
        }
    }, [textRef]);

    const expandReview = () => {
        textRef.current.classList.toggle('text-expanded');
        setNeedExpand(false);
    };

    return (
        <div className={bseo()}>
            {review.stars > 0 && (
                <SeoReview {...{ ...review, submittedAt: review.createdAt, user: review.providedBy }} />
            )}
            <div className="d-flex mb-2">
                <UserCircleImage
                    className={b('avatar')}
                    image={ImageHelper.getUrlFromOptions(review.providedBy.avatarAws, `full-fit-in/${IMG_RESIZES.x64}`)}
                    useState={useStateSSR}
                    SSR={!!useStateSSR}
                />
                <div>
                    <div className="snif-p snif-semibold mb-05">{review.providedBy.nameAndInitial}</div>
                    <div className="snif-s2 snif-regular text-medium">{date}</div>
                </div>
            </div>
            <Rating className="mb-2" value={review.stars} showNmb={false} />
            <div ref={textRef} className="review-text snif-s1 snif-regular text-dark">
                {review.comment}
            </div>
            {needExpand && (
                <SniffButton className="review-expand-btn" size="sm" color="secondary" onClick={expandReview}>
                    Read more
                </SniffButton>
            )}
        </div>
    );
};

export default ReviewCard;
