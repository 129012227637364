import { gql } from '@apollo/client';
import { REVIEW_SHORT, REVIEW_SINGLE_SHORT } from './review-fragments';

export const HOMEPAGE_QUERY = gql`
    query homePage {
        homePage {
            agilitySpotsCount
            beachSpotsCount
            fieldSpotsCount
            fullyFencedCount
            indoorSpotsCount
            moreThanHalfAcreSpotsCount
            swimmingSpotsCount
            trailSpotsCount
            allCategories {
                id
                slug
                staticPosts {
                    id
                    slug
                    title
                    thumbnail
                    descriptionShort
                }
            }
            recentPosts {
                id
                title
                thumbnail
                slug
            }
            popularPosts {
                id
                title
                thumbnail
                slug
            }
            recentReviews {
                ...ReviewShort
            }
        }
    }
    ${REVIEW_SHORT}
`;

export const HOMEPAGE_REVIEWS_QUERY = gql`
    query homeReviews {
        homePage {
            recentReviews {
                ...ReviewSingleShort
            }
        }
    }
    ${REVIEW_SINGLE_SHORT}
`;
